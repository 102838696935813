export default{
    namespace:true,
    state: {
        user:(localStorage.getItem("loginData")&&JSON.parse(localStorage.getItem("loginData")))||{}
    },
    getters: {
    },
    mutations: {
      setUser(state,uinfo){
        state.user=uinfo
      }
    },
    actions: {
    }
  }
  