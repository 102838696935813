import { ref } from 'vue';
export function clearData(obj){
    // 遍历对象属性，将每个值设置为空或null
    for (let key in obj) {
        if (typeof obj[key] === 'string') {
            obj[key] = '';
        } else if (Array.isArray(obj[key])) {
            obj[key] = [];
        } else {
            obj[key] = null;
        }
    }
}

export const api_path = process.env.NODE_ENV=="development"?"api/":""
export const ws_path = process.env.NODE_ENV=="development"?"socket/":""
export function useWebSocket(url) {
    let socket;
    const receivedMessage = ref('');
    let sendMessage, closeWebSocket;
    try { 
      const createWebSocket = () => {
        socket = new WebSocket(url);
    
        socket.onopen = () => {
          console.log('WebSocket connection established');
        };
    
        socket.onmessage = (event) => {
          console.log(event)
          receivedMessage.value = event.data; // 更新收到的消息
        };
        socket.onclose = ()=>{
          console.log('WebSocket disconnect');
        }
      };
    
      sendMessage = (message) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
          socket.send(message);
        }
      };
    
      closeWebSocket = () => {
        if (socket) {
          socket.close();
        }
      };
    
      createWebSocket();
      
    } catch (error) {
      console.log(error)
    }
   
  
    return { receivedMessage, sendMessage, closeWebSocket };
}


