<template>
    <div class="common-layout">
        <el-container>
            <!-- 侧边栏 -->
        <el-aside width="200px">
            <el-menu
                default-active="2"
                class="el-menu-vertical-demo"
                :collapse="false"
                :router="true"
                >
                <!-- <el-menu-item index="/">
                    <el-icon><document /></el-icon>
                    <template #title>概览</template>
                </el-menu-item> -->
                <el-menu-item index="images">
                    <el-icon><document /></el-icon>
                    <template #title>备份管理</template>
                </el-menu-item>
                <el-menu-item index="amz_account">
                    <el-icon><Money /></el-icon>
                    <template #title>账号管理</template>
                </el-menu-item>
                <el-menu-item index="zdmlist">
                    <el-icon><DataAnalysis /></el-icon>
                    <template #title>值得买数据</template>
                </el-menu-item>
                <el-menu-item index="scheduler">
                    <el-icon><Clock /></el-icon>
                    <template #title>定时任务</template>
                </el-menu-item>
                <el-menu-item index="phone">
                    <el-icon><setting /></el-icon>
                    <template #title>设备管理</template>
                </el-menu-item>
               
            </el-menu>
        </el-aside>
        <el-container>
            <!-- 头部 -->
            <el-header>
            <el-row class="header-row" justify="end">
            <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link ">
            <el-icon class="el-icon--left haeder-user-icon" style="vertical-align: middle"><User /></el-icon>
            <span>{{ storedate.username }}</span>
            <el-tag class="el-icon--right" size="small">{{storedate.lv==0?"管理员":storedate.lv==1?"会员":storedate.lv==2?"超级会员":"至尊会员 lv"+storedate.lv}}</el-tag>
            <el-icon class="el-icon--right" style="vertical-align: middle"><arrow-down /></el-icon>
            </span>
            <template #dropdown>
            <el-dropdown-menu>
            <el-dropdown-item command="exit" class="exitCommand">退出登录</el-dropdown-item>
            </el-dropdown-menu>
            </template>
            </el-dropdown>

            </el-row>

            </el-header>
            <!-- 内容 -->
            <div>
                <RouterView/>
            </div>
        </el-container>
      </el-container>
    </div>
  </template>
  
<script>
import {useStore} from 'vuex'
import { reactive,toRefs} from 'vue';
import {useRouter} from "vue-router"
import {logoutApi} from "@/util/request"
export default {
    setup(){
        const date = reactive({
            name:""
        })
        const router = useRouter()
        const store = useStore()
        const handleCommand=(com)=>{
            if (com=="exit"){
                logoutApi()
                .then(res=>{
                    console.log(res)
                    if(res.code==200){
                        localStorage.removeItem("loginData")
                        store.commit("setUser",{})
                        router.push({
                            path:"/login"
                        })
                    }
                   
                })
            }
        }
        const storedate = store.state.userinfo.user
        console.log(storedate)
        return{
            ...toRefs(date),
            handleCommand,
            storedate
        }
    }

}
</script>
<style>
    :focus-visible {
        outline: none; /* 或 outline: 0; */
    }
    .common-layout{
        background-color: #f3f7fc;
    }
    .el-aside{
        background-color: #fff;
    }
    .example-showcase .el-dropdown-link {
        cursor: pointer;
        color: var(--el-color-primary);
        display: flex;
        align-items: center;
    }
    .exitCommand{
        color: #ff5f57!important;
    }
    .header-row{
        height: 100%;
    }
    header .el-dropdown{
        align-self: center;
    }
    .haeder-user-icon{
        border: 1px solid var(--el-text-color-regular);
        border-radius: 50%;
        padding: 3px;
    }
    .el-breadcrumb{
        padding-left: 20px;
    }
</style>