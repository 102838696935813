import axios from "axios"
import { ElLoading,ElMessage} from "element-plus"
axios.defaults.withCredentials = true;
var loadingObj = null
const service = axios.create({
    timeout:60000,
    headers:{
        "Content-Type":"application/json;charset=utf-8"
    }
})
service.interceptors.request.use(config=>{
    loadingObj = ElLoading.service({
        lock:true,
        text:"Loading",
        background:"rgba(0,0,0,0.7)"
    })
    return config 
})

service.interceptors.response.use(response=>{
    loadingObj.close()
    return response.data 
},error=>{
    console.log(error)
    loadingObj.close()
    ElMessage({
        showClose: true,
        message: '服务器错误',
        type: 'error',
        duration:2000
    })
})


export const post = config=>{
    return service({
        ...config,
        method:"post",
        data:config.data
    })
}

export const get = config=>{
    return service({
        ...config,
        method:"get",
        data:config.data
    })
}