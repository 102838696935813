import { createRouter, createWebHashHistory } from 'vue-router'
import LayOut from '../views/LayOut/LayOut'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'LayOut',
    component: LayOut,
    redirect:"/images",
    children:[
      {
        path: '/scheduler',
        name: 'scheduler',
        component:()=> import("../views/pages/scheduler.vue")
      },
      {
        path: '/sche_log',
        name: 'sche_log',
        component:()=> import("../views/pages/scheLog.vue")
      },
      {
        path: '/phone',
        name: 'phoneComp',
        component:()=> import("../views/pages/phone.vue")
      },
      {
        path: '/images',
        name: 'imagesComp',
        component:()=> import("../views/pages/imagesComp.vue"),
      },
      {
        path: '/addImages',
        name: 'addImagesComp',
        component:()=> import("../views/pages/addImages.vue")
      },
      {
        path: '/amz_account',
        name: 'amz_account',
        component:()=> import("../views/pages/amz_account.vue")
      },
      {
        path: '/add_amz_account',
        name: 'add_amz_account',
        component:()=> import("../views/pages/add_amz_account.vue")
      },
      {
        path: '/zdmlist',
        name: 'zdmlist',
        component:()=> import("../views/pages/zdmlist.vue")
      },
    ]
  },
  {
    path: '/login',
    name: 'loginComp',
    component:()=> import("../views/pages/Login.vue")
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to,from,next)=>{
  const info = store.state.userinfo.user
  // console.log(info)
  if(!info.username){
    if(to.path=="/login"){
      next()
      return
    }
    next("/login")
  }else{
    next()
  }
})
export default router
