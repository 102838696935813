import {post,get} from "./service"
import {api_path} from "./common"
const proxy_path = api_path
export const loginApi = data=>{
    return post({
        url:"/"+proxy_path+"login/",
        data
    })
}

export const logoutApi = ()=>{
    return get({
        url:"/"+proxy_path+"logout/",
    })
}


export const getPhone = ()=>{
    return get({
        url:"/"+proxy_path+"phone/get/",
    })
}

export const getUsers = ()=>{
    return get({
        url:"/"+proxy_path+"getUsers/",
    })
}

export const addPhone = data=>{
    return post({
        url:"/"+proxy_path+"phone/add/",
        data
    })
}

export const deletePhone = data=>{
    return post({
        url:"/"+proxy_path+"phone/delete/",
        data
    })
}

export const updatePhone = data=>{
    return post({
        url:"/"+proxy_path+"phone/update/",
        data
    })
}

export const getImages = (phone)=>{
    return get({
        url:"/"+proxy_path+"images/get/?phone="+phone,
    })
}


export const stopImages = (ip,name)=>{
    return get({
        url:`/`+proxy_path+`sdk/stop/${ip}/${name}`
    })
}

export const screen = data=>{
    return post({
        url:"/"+proxy_path+"screen/",
        data
    })
}

export const addImage = data=>{
    return post({
        url:"/"+proxy_path+"images/add/",
        data
    })
}

export const deleteImage = data=>{
    return post({
        url:"/"+proxy_path+"images/delete/",
        data
    })
}

export const updateImage = data=>{
    return post({
        url:"/"+proxy_path+"images/update/",
        data
    })
}

export const getApks = (ip,name)=>{
    return get({
        url:"/"+proxy_path+"apk/get/"
    })
}

export const installApk = (data)=>{
    return post({
        url:"/"+proxy_path+"apk/install/",
        data
    })
}

export const autoRun = (ip,name)=>{
    return get({
        url:`/`+proxy_path+`auto/run?ip=${ip}&name=${name}&tp=yanghao`
    })
}
export const zdmqiandaoRun = (ip,name,type)=>{
    return get({
        url:`/`+proxy_path+`runscript/?ip=${ip}&name=${name}&tp=${type}`
    })
}
export const ipTest = (data)=>{
    return post({
        url:"/"+proxy_path+"iptest/",
        data
    })
}

export const get_amz_account = (data)=>{
    return get({
        url:"/"+proxy_path+"amz_account/get/",
    })
}
export const get_email = (user,psw)=>{
    return get({
        url:"/"+proxy_path+"get_email/?email="+user+"&email_psw="+psw,
    })
}

export const amz_account_add_emails = data=>{
    return post({
        url:"/"+proxy_path+"amz_account/add_use_emails/",
        data
    })
}
export const amz_account_delete = data=>{
    return post({
        url:"/"+proxy_path+"amz_account/delete/",
        data
    })
}
export const amz_account_update = data=>{
    return post({
        url:"/"+proxy_path+"amz_account/update/",
        data
    })
}

export const get_scheduler = (phone_id)=>{
    return get({
        url:"/"+proxy_path+"scheduler/getJobs/?phone_id="+phone_id,
    })
}
export const del_scheduler = (data)=>{
    return post({
        url:"/"+proxy_path+"scheduler/delJobs/",
        data
    })
}

export const scheduler_status = ()=>{
    return get({
        url:"/"+proxy_path+"scheduler/status/"
    })
}

export const scheduler_start = ()=>{
    return get({
        url:"/"+proxy_path+"scheduler/start/"
    })
}

export const scheduler_stop = ()=>{
    return get({
        url:"/"+proxy_path+"scheduler/stop/"
    })
}

export const add_scheduler = (data)=>{
    return post({
        url:"/"+proxy_path+"scheduler/addJobs/",
        data:data
    })
}

export const get_scheLog = (phone_id,pageNum)=>{
    return get({
        url:"/"+proxy_path+"scheLog/?phone_id="+phone_id+"&page="+pageNum
    })
}

export const get_zdmlist = (phone_id,pageNum)=>{
    return get({
        url:"/"+proxy_path+"zdm_list/?phone_id="+phone_id+"&page="+pageNum
    })
}

export const getLogByImagesId = (images_id)=>{
    return get({
        url:"/"+proxy_path+"getLogByImagesId/?images_id="+images_id
    })
}